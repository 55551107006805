.App {
  text-align: center;
}

p {
  font-family: 'Segoe UI', 'Oxygen', Arial, sans-serif;
}

.success-message p {
  font-family: Nunito, 'Helvetica', -apple-system, BlinkMacSystemFont, sans-serif;
}

.survey-label {
  display: block;
  margin-top: 2rem;
  font-weight: 600;
  font-family: Nunito, 'Helvetica', -apple-system, BlinkMacSystemFont, sans-serif;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.bg-accentlight {
  background-color: #f1f7e9;
}

.bg-circles {
  background-image: url("./assets/bg_circles.png");
  background-repeat: no-repeat;
  background-size: cover;
	background-position: 70% 50%;
}

.bg-dark {
  background: #354052;
}

.bg-gradient {
  background: linear-gradient(#354052, #045B7E)
}

.bg-houses {
  background-image: url("./assets/bg_houses.png");
  background-repeat: no-repeat;
  background-size: cover;
	background-position: 70% 50%;
}

.bg-primarylight {
  background-color: #EAF7FE
}

.bg-white {
  background: white;
}

.btn-s {
  height: 24px;
}

.btn-m {
  height: 44px;
}

.btn-l {
  font-size: 1.15rem;
  min-width: 280px;
  font-weight: 600;
  height: 40px;
}

.close-waitlist-btn {
  position: absolute;
  right: 8px;
  top: 8px;
  background: transparent;
  border: 0;
}

.close-waitlist-btn {
  position: absolute;
  right: 8px;
  top: 8px;
  background: transparent;
  border: 0;
  color: #ccc;
}

.close-waitlist-btn:before {
  position: absolute;
  font-size: 25px;
  line-height: 35px;
  content: "\00d7";
  cursor: pointer;
}

.close-waitlist-btn:hover {
  color: #354052;
}

.color-accent {
  color: #8CD018;
}

.color-gray {
  color: #CCC; 
}
.color-secondary {
  color: #354052;
}

.color-lightgray {
  color: #E6E6E6;
}

.color-primary {
  color: #49ACF4;
}

.color-white {
  color: white;
}

.cta-main-btn {
  align-items: center;
  justify-content: center;
  border: none;
  margin-top: 4rem;
  min-width: 240px;
  width: 100%;
  max-width: 2px;
  cursor: pointer;
  outline: none;
}

.cta-nav-btn, .cta-main-btn {
  display: flex;
  background-color: #8CD018;
  padding: .5rem;
  border-radius: 6px;
  text-decoration: none;
  color: #FFF;
}

.cta-nav-btn:hover,
.cta-main-btn:hover {
  background-color: #7EBB15;
}

.dropdown-arrow, 
.dropdown-arrow-closed,
.dropdown-arrow-expanded {
	border: solid #354052;
	border-width: 0 2px 2px 0;
  display: inline-block;
  margin: 8px;
	padding: 4px;
  cursor: pointer;
}

.dropdown-arrow-expanded {
  transform: rotate(-135deg);
  border: solid #8CD018;
  border-width: 0 2px 2px 0;
}

.dropdown-arrow-closed {
  transform: rotate(45deg);
}

.dropdown-arrow-container-expanded,
.dropdown-arrow-container-closed {
	background-color: white;
	border-bottom-right-radius: 3px;
	border-top-right-radius: 3px;
  cursor: pointer;
}

.dropdown-arrow-container-expanded {
  padding-top: 4px;
  margin-bottom: -4px;
}

.dropdown-body-hide {
  display: none;
}

.dropdown-body-show {
  display: flex;
  text-align: left;
  padding: 0.5rem;
  background: #d6d6d6;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  background: white;
  border-radius: 4px;
  cursor: pointer;
}

.dropdown-header-text {
  text-align: left;
  font-weight: 500;
}

.dropdown-header:hover .dropdown-arrow-closed {
  border: solid #8CD018;
  border-width: 0 2px 2px 0;
}

.dropdown-root-closed {
  border: 1px solid #BBE5FD;
  width: 100%;
}

.dropdown-root-expanded {
  border: 1px solid #8CD018;
  width: 100%;
}

.error {
  display: block;
  font-size: .8rem;
  color: red;
  margin-top: -8px;
}

.flex-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-spacebetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.faq-root {
  padding: 0;
  display: flex;
  flex-direction: column;
}

.footnote {
  font-size: 0.75rem;
  text-align: center;
  color: #ababab;
  margin-bottom: 4px;
}

.form-header {
  font-size: 1.5rem;
}

.main-top {
  height: 700px;
  display: flex;
  color: white;
}

.main-top-header {
  font-size: 4rem;
  font-weight: 700;
  font-family: 'Raleway', Helvetica, sans-serif;
  position: relative;
}

.main-top-section-img {
  height: 400px;
  width: 100%;
}

.main-top-section.section-parent {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  margin: 72px auto 0 auto;
}

.main-top-subheader {
  margin: 1rem 0;
  text-align: left;
  font-size: 1.25rem;
}

.nav-btn-list {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: auto auto;
  margin-right: 8px;
  padding: 0;
}

.nav-header {
  position: fixed;
  width: 100%;
  background-color: white;
  display: flex;
  padding: 0 .5rem;
  align-items: center;
  color: white;
  box-shadow: rgb(171, 171, 171) 0px 4px 4px -4px;
  z-index: 10;
}

.recaptcha  div.rc-anchor-normal {
  width: 240px;
}

.recaptcha {
  margin-top: 16px;
  margin-left: -12px;
}

.relative {
  position: relative;
}

.section-bottom-cta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-child {
  text-align: left;
  flex: 1;
  padding: 0 2rem;
}

.section-child-left {
  order: 1;
}

.section-child-right {
  order: 2;
}

.section-container {
  display: flex;
  flex: 1 1 auto;
  max-width: 1280px;
  margin: 0 auto;
  min-height: 320px;
  align-items: center;
}

.section-container > li {
  border-radius: 4px;
  margin: 1rem 0;
}

.section-header, .form-header {
  font-family: 'Raleway', 'Helvetica', -apple-system, BlinkMacSystemFont, sans-serif;
  padding: 1rem 0;
}

.section-header {
  font-size: 1.75rem;
}

.section-img {
  height: 400px;
  padding: 2rem 0;
  width: 100%;
}

.section-middle-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 400px;
  max-width: 1280px;
  margin: 1rem auto;
  padding: 1rem 0;
  border: 1px dashed #8CD018;
  border-radius: 8px;
  width: calc(100% - 32px);
}

.section-parent {
  max-width: 1280px;
  margin: 0 auto;
}

.section-root {
  padding: 1.5rem 1rem;
}

.section-subheader {  
  font-family: Nunito, 'Helvetica', -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 1rem 0;
}
.section-text {
  font-size: 1.25rem;
}

.toast {
  border-radius: 8px;
  padding: 8px;
  margin: 8px 0;
}

.toast-success {
  background: #cfecfd;
}

.top-section-child-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  order: 1;
}

.ta-left {
  text-align: left;
}

.top-section-child-right {
  order: 2;
}
.trademark {
  position: absolute;
  right: -12px;
  top: 8px;
  font-size: 1.5rem;
}

.waitlist-btn {
  margin-top: 60px;
  max-width: 360px !important;
  width: 100%;
}

.waitlist-form-root {
  max-width: 360px;
  margin: 0 auto;
  border-radius: 8px;
  padding: 32px 24px 16px 32px;
  position: relative;
  background-image: url("./assets/bg_circles.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 70% 50%;
  max-height: 840px;
  margin-bottom: 1.5rem;
}

.waitlist-page-main {
  min-height: calc(100vh - 80px);
  padding-top: 80px;
}


@media screen and (max-width: 960px)  {
  .main-top {
    height: 600px;
  }
  .main-top-header {
    font-size: 3.2rem;
  }
  .main-top-subheader {
    font-size: 1.25rem;
  }
  .section-header {
    font-size: 1.5rem;
  }
}


@media screen and (max-width: 720px)  {
  .btn-l {
    height: 44px;
  }
  .main-top {
    height: auto;
  }
  .main-top-header {
    font-size: 3.5rem;
  }
  .main-top-subheader, .section-subheader {
    text-align: center;
    font-size: 1.15rem;
  }
  .main-top-section.section-parent {
    margin-top: 4rem;
    margin-bottom: unset;
    flex-direction: column;
    padding: 1.5rem;
  }
  .section-child {
    width: 100%;
    padding: unset;
  }
  .section-child-left {
    margin-top: -32px;
    order: 2;
    width: 100%;
  }
  .section-child-right {
    margin-top: -32px;
    order: 1;
    width: 100%;
  }
  .section-container {
    flex-direction: column;
  }
  .section-img {
    padding: unset;
  }
  .section-text {
    font-size: 1.1rem;
  }
  .top-section-child-left {
    flex-direction: column;
    align-items: center;
    order: 2;
    width: 100%;
    flex: unset;
  }
  .top-section-child-right {
    order: 1;
    flex: unset;
    width: 100%;
  }
  .trademark {
    font-size: 1rem;
  }
}

@media screen and (max-width: 660px) {
  .btn-l {
    height: 32px;
  }
  .cta-main-btn {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
  .main-top-header {
    font-size: 3.5rem;
  }
  .main-top-section-img {
    height: 320px;
  }
  .main-top-subheader {
    text-align: center;
    font-size: 1.2rem;
  }
  .nav-header {
    width: 100vw;
  }
  .section-parent {
    display: flex;
    flex-direction: column;
  }
  .section-child {
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .section-container {
    min-height: unset;
  }
  .section-header, .form-header {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 480px) {
  .cta-main-btn {
    font-size: 1.1rem;
    min-width: 200px;
    max-width: 240px;
  }
  .main-top-header {
    font-size: 2.8rem;
  }
  .section-middle-cta p {
    padding: 0 1rem;
  }
  .top-section-child-right {
    margin-top: unset;
  }
  .waitlist-form-root {
    padding: 1.5rem 1rem;
  }
  .waitlist-page-main {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: unset;
  }
}

@media screen and (max-width: 320px) {
  .waitlist-form-root {
    padding-bottom: 8px;
    max-width: 280px;
  }
  .waitlist-page-main {
    min-height: calc(100vh - 70px);
    padding-top: 70px;
    padding-bottom: unset;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer-copyright {
  border-top: 1px solid lightgray;
  padding: 0.5rem;
  margin-top: .5rem;
}
.footer-follow-us {
  display: none;
}
.footer-icons {
  display: flex;
  flex-direction: column;
  padding: .5rem.5rem;
}
.footer-icons-social {
  display: flex;
  align-items: center;
  padding-top: .5rem;
  font-size: .75rem;
  padding-left: 8px;
}
.footer-icons-social > * {
  margin-right: 8px;
}
@media screen and (min-width: 480px) {
  .footer-follow-us {
    display: flex;
  }
  .footer-icons {
    justify-content: space-between;
    flex-direction: column;
  }
  .footer-icons-social {
    padding: unset;
    padding-left: 8px;
  }
}

.social-icon {
	height: 28px;
	width: 28px;
}
@media screen and (min-width: 660px) {
  .social-icon {
    height: 36px;
    width: 36px;
  }
  .footer-icons-social {
    font-size: 1rem;
  }
}

.fb-icon {
  background-image: url("./assets/icon-fb.svg");
  background-repeat: no-repeat;
  background-size: cover;
	background-position: 70% 50%;
}
.fb-icon:hover {
  background-image: url("./assets/icon-fb-hover.svg");
}

.ig-icon {
  background-image: url("./assets/icon-insta.svg");
  background-repeat: no-repeat;
  background-size: cover;
	background-position: 70% 50%;
}
.ig-icon:hover {
  background-image: url("./assets/icon-insta-hover.svg");
}

.tw-icon {
  background-image: url("./assets/icon-tw.svg");
  background-repeat: no-repeat;
  background-size: cover;
	background-position: 70% 50%;
}
.tw-icon:hover {
  background-image: url("./assets/icon-tw-hover.svg");
}
